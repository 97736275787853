<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="page-title text-center">Lernen Sie uns kennen</h1>
        <h2 class="display-p text-center mb-5">
          Wir lieben unsere Arbeit!
        </h2>
      </div>
    </div>

  </div>
  <div class="fullwidth-container testimonials">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2>Referenzen</h2>
        <p class="display-p mb-5">
          Auf unsere zufriedenen Kunden sind wir stolz.
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div
        class="col-sm-12 col-md-4 col-lg-3"
        v-for="reference in references"
        :key="reference.id"
      >
        <div class="reference-item">
          <div
            class="reference-body d-flex flex-column justify-content-center mb-4"
          >
            <div class="product-thumbnail">
              <figure class="image mt-2 mb-2">
                <img :src="reference.thumbnail" alt="Logo" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="fullwidth-container testimonials">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2>Das Team hinter scanner4you</h2>
        <p class="display-p mb-5">
          Ihre Spezialisten für 3D-Laserscanning.
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div
        class="col-sm-12 col-md-4 col-lg-3"
        v-for="employee in employees"
        :key="employee.id"
      >
        <div class="reference-item">
          <div class="product-thumbnail">
              <figure class="image">
                <img :src="employee.avatar" class="employee-img" alt="Logo" />
              </figure>
            </div>
          <div
            class="employee-body d-flex flex-column justify-content-center mb-4"
          >
            <div class="employee-name">{{ employee.first_name }} {{ employee.last_name }}</div>
            <div class="employee-position mb-4">{{ employee.position }}</div>
            <div class="employee-contact-information"><a href="tel:{{ employee.phone }}">{{ employee.phone }}</a>  <a href="mailto:{{ employee.mail }}">{{ employee.mail }}</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="fullwidth-container testimonials">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>Wir schätzen Rückmeldungen</h2>
          <p class="mb-4">
            Vertrauen Sie auf das Feedback unserer Kunden.
          </p>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-12 col-md-6"
          v-for="testimonial in testimonials"
          :key="testimonial.id"
        >
          <div class="reference-item">
            <div class="reference-body d-flex flex-column mb-4">
              <div class="d-flex mb-3">
                <span v-for="i in testimonial.stars" :key="i">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="injected-svg icon"
                  data-src="assets/img/icons/interface/star.svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3616 17.7407L8.27722 19.888C7.78838 20.145 7.18375 19.957 6.92675 19.4682C6.82441 19.2735 6.7891 19.0505 6.82627 18.8338L7.60632 14.2858L4.30199 11.0648C3.90651 10.6793 3.89841 10.0462 4.28391 9.65073C4.43742 9.49325 4.63856 9.39076 4.8562 9.35913L9.42268 8.69559L11.4649 4.55766C11.7093 4.0624 12.3089 3.85906 12.8042 4.10349C13.0014 4.20082 13.161 4.36044 13.2583 4.55766L15.3005 8.69559L19.867 9.35913C20.4136 9.43855 20.7922 9.94599 20.7128 10.4925C20.6812 10.7102 20.5787 10.9113 20.4212 11.0648L17.1169 14.2858L17.8969 18.8338C17.9903 19.3781 17.6247 19.8951 17.0804 19.9884C16.8636 20.0256 16.6406 19.9903 16.446 19.888L12.3616 17.7407Z"
                    fill="#ffc107"
                  ></path>
                </svg>
              </span>
              </div>
              <p class="client-review-text mb-3">
                {{ testimonial.text }}
              </p>
              <div class="avatar-author align-items-center">
                <img :src="testimonial.avatar" class="avatar"/>
                <div class="ml-2">
                  <p class="avatar-name">{{ testimonial.name }}, {{ testimonial.company }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Referenzen",
  data() {
    return {
      references: [],
      testimonials: [],
      employees: [],
    };
  },
  mounted() {
    document.title = "Ihr Ansprechpartner für Laserscanning - scanner4you";
    this.getReferences();
    this.getTestimonials();
    this.getEmployees();
  },
  methods: {
    async getReferences() {
      try {
        const response = await axios.get("/api/v1/references/");
        this.references = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getTestimonials() {
      try {
        const response = await axios.get("/api/v1/testimonials/");
        this.testimonials = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getEmployees() {
      try {
        const response = await axios.get("/api/v1/employees/");
        this.employees = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

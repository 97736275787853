<template>
  <div>
    <div class="product-price">1. Mietdatum wählen</div>
    <div class="product-subprice">Wann soll die Messung stattfinden?</div>
  </div>
  <div>
    <div class="row  d-flex g-2 calender-radio">
      <div v-for="(deliveryOption, index) in deliveryOptions" :key="deliveryOption.id" class="col-6">
        <div :class="[{'selected': pickedDeliveryOption === index},'radio-container']">
          <input type="radio" :id="deliveryOption.id" name="options" class="radio-input" :value="index"
            v-model="pickedDeliveryOption" @change="resetCalendar(this)" :checked="pickedDeliveryOption === index" 
            />
          <label class="radio-label" :for="deliveryOption.id">
            <div class="product-delivery-option">{{ deliveryOption.name }}</div>
            <span class="product-delivery-description">{{ deliveryOption.description }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="calendar product-item ">
      <div ref="week" class="product-body-rental mb-3">
        <div class="row">
          <div class="col d-flex justify-content-start">
            <button class="btn-bg-none" style="padding-left: 0"
              v-on:click="cDate.setMonth(cDate.getMonth() - 1); this.calenderRender(this);">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg>
            </button>
          </div>
          <div class="col text-center month-name" ref="month"></div>
          <div class="col  d-flex justify-content-end">
            <button class="btn-bg-none" style="padding-right: 0"
              v-on:click="cDate.setMonth(cDate.getMonth() + 1); this.calenderRender(this);">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
        </div>
        <div class="row weekHead">
          <div class="col day">Mo</div>
          <div class="col day">Di</div>
          <div class="col day">Mi</div>
          <div class="col day">Do</div>
          <div class="col day">Fr</div>
          <div class="col day">Sa</div>
          <div class="col day">So</div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
        <div class="row week">
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
          <div class="col day" v-on:click="setBooking($event, this)"></div>
        </div>
      </div>
      <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
    </div>
  </div>
  <div class="produt-service-options" :class="{'is-disabled': !setRentalPeriod}">
    <div class="row">
      <div class="product-price">2. Datenverarbeitung wählen</div>
      <div class="product-subprice">Wie soll die 3D-Punktwolke ausgewertet werden?</div>
    </div>
    <div class="row d-flex g-2">
      <div v-for="(productService, index) in productServices" :key="productService.id" class="col-md-6">
        <div class="product-services" @click="handleProductServiceClick($event, index)"
          :class="{ 'selected': selectedProductServiceIndex === index }">
          <div class="service-title">{{ productService.name }}</div>
          <div class="service-description">{{ productService.description }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-pricing" :class="{'is-disabled': !setRentalPeriod}">
    <div class="product-price" v-if="this.rentalStart && this.rentalEnd">
      {{ new Intl.NumberFormat('de-CH', {
        style: 'currency', currency: 'CHF', trailingZeroDisplay: 'stripIfInteger'
      }).format(totalRentalPrice) }}
    </div>
    <div class="product-price" v-else>CHF 0</div>
    <div class="product-subprice">Gesamtpreis exkl. MwSt.</div>
  </div>
  <div class="row">
    <div class="col">
      <div class="bundle-buttons">
        <button :disabled="!(this.rentalStart && this.rentalEnd)" class="add-to-cart" v-on:click="rent(this)">
          <i class="icon-cart"></i>In den Warenkorb
        </button>
        <button class="button-dark">
          <a href="tel:+41 71 311 44 00"><i class="icon-phone"></i>Wir helfen gerne</a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
export default {
  name: "ProductCalendar",
  props: {
    product: Object,
  },
  components: {
    Toast,
  },
  data() {
    return {
      bookings: [],
      cDate: new Date(),
      rentalStart: null,
      rentalEnd: null,
      toasttitle: "scanner4you",
      toastbody: "",
      pickedDeliveryOption: 0,
      deliveryOptions: [],
      productServices: [],
      selectedProductServiceIndex: 0
    };
  },
  mounted() {
  },
  created() {
    axios
      .get("/api/v1/products/bookings/" + this.product.id + "/")
      .then((response) => {
        this.bookings = response.data;
        this.calenderRender(this);
      });

    axios
      .get("/api/v1/products/deliverytypes/")
      .then((response) => {
        this.deliveryOptions = response.data;
      });

    this.productServices = this.product.productservices;

  },
  updated() {
    axios
      .get("/api/v1/products/bookings/" + this.product.id + "/")
      .then((response) => {
        this.bookings = response.data;
        this.calenderRender(this);
      });
  },
  methods: {
    handleProductServiceClick(event, index) {
      let allProductServices = document.querySelectorAll('.product-services');
      allProductServices.forEach((element) => {
        element.classList.remove('selected');
      });

      // Add 'selected' class to the clicked element
      event.currentTarget.classList.add('selected');

      // Update selectedIndex to the clicked index
      this.selectedProductServiceIndex = index;
    },
    resetCalendar(data) {
      console.log(data);
      data.bookings = [];
      data.cDate = new Date();
      data.rentalStart = null;
      data.rentalEnd = null;
      data.toasttitle = "scanner4you";
      data.toastbody = "";
    },
    rent(data) {
      const item = {
        product: data.product,
        quantity: 1,
        daycount: (data.rentalEnd - data.rentalStart) / 86400000 + 1,
        rentalstartdate: data.rentalStart.toLocaleDateString("de-CH", { year: "numeric", month: "2-digit", day: "2-digit" }),
        rentalenddate: data.rentalEnd != null ? data.rentalEnd.toLocaleDateString("de-CH", { year: "numeric", month: "2-digit", day: "2-digit" }) : data.rentalStart.toLocaleDateString("de-CH", { year: "numeric", month: "2-digit", day: "2-digit" }),
        delivery: data.deliveryOptions[data.pickedDeliveryOption],
        productservice: data.product.productservices[data.selectedProductServiceIndex]
      };
      console.log(item);
      this.$store.commit("addToCart", item);
      this.toastbody = "Der Mietartikel wurde dem Warenkorb hinzugefügt."
      $('.toast').toast('show');
    },
    setBooking: (event, data) => {
      if (
        !event.currentTarget.classList.contains("inactive") &&
        !event.currentTarget.classList.contains("booked") &&
        !event.currentTarget.classList.contains("past") &&
        !event.currentTarget.classList.contains("deactivated")
      ) {
        if (data.rentalStart == null) {
          data.rentalStart = new Date(data.cDate.getTime());
          data.rentalStart.setHours(0, 0, 0, 0);
          data.rentalStart.setDate(parseInt(event.currentTarget.innerHTML));
        } else if (data.rentalEnd == null) {
          let tmpDate = new Date(data.cDate.getTime());
          tmpDate.setHours(0, 0, 0, 0);
          tmpDate.setDate(parseInt(event.currentTarget.innerHTML));
          if (
            data.rentalStart <= tmpDate
          ) {
            data.rentalEnd = new Date(data.cDate.getTime());
            data.rentalEnd.setHours(0, 0, 0, 0);
            data.rentalEnd.setDate(parseInt(event.currentTarget.innerHTML));
            data.calenderRender(data);
          } else {
            data.rentalStart = new Date(data.cDate.getTime());
            data.rentalStart.setHours(0, 0, 0, 0);
            data.rentalStart.setDate(parseInt(event.currentTarget.innerHTML));
            data.calenderRender(data);
          }
        } else if ((data.rentalStart != null) & (data.rentalEnd != null)) {
          data.rentalStart = new Date(data.cDate.getTime());
          data.rentalStart.setHours(0, 0, 0, 0);
          data.rentalStart.setDate(parseInt(event.currentTarget.innerHTML));
          data.rentalEnd = null;
          data.calenderRender(data);
        }

      }
    },
    calenderRender: (data) => {
      console.log(`data: ${JSON.stringify(data.bookings)}`);
      const month = [
        "Januar",
        "Februar",
        "M&auml;rz",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
      data.$refs.month.innerHTML = month[data.cDate.getMonth()] + " " + data.cDate.getFullYear();

      let date = data.cDate,
        y = date.getFullYear(),
        m = date.getMonth();

      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);

      let startIndex = (firstDay.getDay() + 6) % 7;
      let maxIndex = new Date(y, m + 1, 0).getDate() - 1;
      let tmpIndex = 0;

      let bookedDays = [];
      let bookedDaysData = [];
      if (data.bookings.bookedPickupBookings.length > 0 || data.bookings.bookedDeliveryBookings.length > 0) {
        if (data.deliveryOptions[data.pickedDeliveryOption].price === '0.00') //pickup
          bookedDaysData = data.bookings.bookedPickupBookings;
        else                                                                  //delivery
          bookedDaysData = data.bookings.bookedDeliveryBookings;
        bookedDays = bookedDaysData
          .filter((booking) => { return booking.notBooked.length == 0; })
          .map((booking) => {
            let date = new Date(booking.rentaldate);
            date.setHours(0, 0, 0, 0);
            return date;
          });
      }
      let isInArray = (array, value) => { return !!array.find(item => { return item.getTime() == value.getTime() }); };

      let hideCalenderEntry = function hide(calenderEntry) {
        calenderEntry.classList = [];
        calenderEntry.classList.add("col", "day", "inactive");
        calenderEntry.innerHTML = "";
      };

      let formatCalenderEntry = (calenderEntry) => {
        calenderEntry.classList = [];
        calenderEntry.classList.add("col", "day");
        calenderEntry.innerHTML = "";
      };

      let locateCalenderEntry = (index) => {
        return data.$refs.week.childNodes[Math.floor(index / 7) + 2].childNodes[index % 7];
      };

      let deactivateSundays = (index, element) => {
        if ((index + 1) % 7 == 0)
          element.classList.add("deactivated");
      };

      let deactivateSaturdays = (index, element) => {
        if ((index + 1) % 7 == 6)
          element.classList.add("deactivated");
      };

      let deactivatePastTime = (elementTime, element) => {
        let now = new Date();
        let tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);

        //block Mondays on Friday
        if(now.getDay() === 5)
          tomorrow.setDate(tomorrow.getDate() + 3);

        if (elementTime.getTime() < tomorrow.getTime())
          element.classList.add("past");
      };

      let deactivateBookedDay = (bookingDate, element) => {
        let tmpBookedDays = [...bookedDays];
        if (isInArray(tmpBookedDays, bookingDate))
          element.classList.add("booked");
      };

      let deactivateIdentityInconsistentBookingDays = (rentalStart, bookingDate, element) => {
        if (rentalStart !== null && bookedDaysData.length > 0 && bookingDate >= rentalStart){
          let tmpBookedDaysData = [...bookedDaysData];
          const dayIndexesInBetween = [];
          let date = new Date(rentalStart);
          let availableIdentities = [...tmpBookedDaysData[0].booked, ...tmpBookedDaysData[0].notBooked];
          while (date <= bookingDate){
            let currentBookingIndex = date.toLocaleDateString('en-CA');
            let dataSource = tmpBookedDaysData.find(booking => booking.rentaldate === currentBookingIndex);
            if (dataSource !== undefined)
              availableIdentities = listDifference(availableIdentities,dataSource.booked);
            date.setDate(date.getDate()+1);
          }
          if (availableIdentities.length === 0)
            element.classList.add("booked");
        }
      };

      let calculateCurrentCalendarViewDay = (dayOfMonth) => {
        let currentCalendarViewMonthDay = new Date(data.cDate.getTime());
        currentCalendarViewMonthDay.setHours(0, 0, 0, 0);
        currentCalendarViewMonthDay.setDate(dayOfMonth);
        return currentCalendarViewMonthDay;
      };

      let arrayContainsDateWindow = (src, start, end) => {
        return src.some(element => { return element.getTime() >= start.getTime() && element.getTime() <= end.getTime(); });
      };

      let listDifference = (src,cmp) => {
        return src.filter(n => !cmp.includes(n));
      };

      while (tmpIndex <= 41) {
        let tmpElement = locateCalenderEntry(tmpIndex);
        formatCalenderEntry(tmpElement);

        if (tmpIndex >= startIndex && tmpIndex <= maxIndex + startIndex) {

          let dayOfMonth = tmpIndex - startIndex + 1;

          let currentCalenderViewDay = calculateCurrentCalendarViewDay(dayOfMonth);

          if (data.rentalStart != null && currentCalenderViewDay.getTime() === data.rentalStart.getTime() && data.rentalEnd == null ||
            data.rentalStart != null && currentCalenderViewDay.getTime() >= data.rentalStart.getTime() && data.rentalEnd != null && currentCalenderViewDay.getTime() <= data.rentalEnd.getTime() && !arrayContainsDateWindow(bookedDays, data.rentalStart, data.rentalEnd)) {
            //gruesse an die kalender-auswahl-anforderung
            if (data.rentalEnd != null && data.rentalStart != null && currentCalenderViewDay.getTime() === data.rentalStart.getTime() && currentCalenderViewDay.getTime() === data.rentalEnd.getTime()) {
              tmpElement.classList.remove("booking-start")
              tmpElement.classList.add("booking-single");
            }
            else
              if (data.rentalStart != null && currentCalenderViewDay.getTime() === data.rentalStart.getTime())
                tmpElement.classList.add("booking-start");
              else
                if (data.rentalEnd != null && currentCalenderViewDay.getTime() === data.rentalEnd.getTime())
                  tmpElement.classList.add("booking-end");
                else
                  tmpElement.classList.add("booking-between");
          }
          else
            if (data.rentalEnd != null && data.rentalStart != null && arrayContainsDateWindow(bookedDays, data.rentalStart, data.rentalEnd)) {
              data.rentalEnd = bookedDays
                .filter(date => date > data.rentalStart)
                .reduce((minDate, date) => (date < minDate ? date : minDate), null);
              if (data.rentalEnd != null)
                data.rentalEnd.setDate(data.rentalEnd.getDate() - 1);
            }
          
          let pickupSelected = data.deliveryOptions[data.pickedDeliveryOption].price === '0.00';
          
          if (pickupSelected)
            deactivateSaturdays(tmpIndex, tmpElement);
          else if (data.rentalStart != null && data.rentalEnd === null)
            deactivateSaturdays(tmpIndex, tmpElement);

          deactivateSundays(tmpIndex, tmpElement);
          deactivatePastTime(currentCalenderViewDay, tmpElement);
          deactivateBookedDay(currentCalenderViewDay, tmpElement);
          deactivateIdentityInconsistentBookingDays(data.rentalStart,currentCalenderViewDay, tmpElement);

          tmpElement.innerHTML = dayOfMonth;

        } else {
          hideCalenderEntry(tmpElement);
        }
        tmpIndex += 1;
      }
    },
  },
  computed: {
    setRentalPeriod() {
      return this.rentalStart !== null && this.rentalEnd !== null;
    },
    totalRentalPrice() {
      let sundays = (start, end) => {
        let currentDate = new Date(start.getTime());
        let counter = 0;
        while (currentDate.getTime() < end.getTime()) {
          if (currentDate.getDay() === 0)
            counter++;
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return counter;
      };
      let days = (parseInt((this.rentalEnd - this.rentalStart) / 86400000) + 1) - sundays(this.rentalStart, this.rentalEnd);
      let price = days * parseFloat(this.product.price);
      if (days < 1) {
        price = 0;
      }

      price += parseFloat(this.deliveryOptions[this.pickedDeliveryOption].price);
      price += parseFloat(this.product.productservices[this.selectedProductServiceIndex].price);

      return price;
    },
  }
};
</script>

<style>
input[type='radio'] {
  accent-color: #688d3a;
}
</style>
<template>
  <div v-if="loaded">
    <div class="container product-view">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <figure class="image mb-6">
            <carousel :product="event" />
          </figure>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row mb-4">
            <div class="col-sm-12 col-md-9">
              <h1 class="product-title">{{ event.name }}
                <br>
                <span class="product-subtitle">{{ event.subtitle }}</span>
              </h1>
              <div v-html="event.content" class="product-description"></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="bundle-buttons">
                <button class="add-to-cart" @click="goToRegistration">
                  Jetzt kostenlos anmelden
                </button>
                <button class="button-dark">
                  <a href="tel:+41 71 311 44 00"><i class="icon-phone"></i>Wir helfen gerne</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Carousel from "@/components/Carousel.vue";
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: "Product",
  data() {
    return {
      loaded: false,
      event: {},
    };
  },
  components: {
    Spinner,
    Carousel,
    Breadcrumb,
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    async getEvent() {
      const event_slug = this.$route.params.slug;

      await axios
        .get(`/api/v1/events/${event_slug}`)
        .then((response) => {
          this.event = response.data;
          this.loaded = true;
          document.title = `${this.event.name} - scanner4you`;
        })
        .catch((error) => {
          console.log('Fehler beim Laden des Events:', error);
        });
    },
    goToRegistration() {
      this.$router.push({ name: 'EventRegistration' });
    },
  },
};
</script>
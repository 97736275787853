<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="page-title text-center">scanner4you unterwegs</h1>
        <h2 class="display-p text-center mb-5">
          Lernen Sie unsere Produkte und Dienstleistungen kennen!<br />
          Mehr Informationen sind unter dem jeweiligen Anlass ersichtlich.<br />
          <br />
          In welchem Stadion sehen wir uns?<br />
          <button class="add-to-cart mt-4" @click="goToRegistration" style="display: flex;justify-self: center;">Kostenlos anmelden</button>
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
          <div class="col-sm-12 col-md-6 col-lg-4" v-for="event in events" :key="event.id">
            <a :href="'/events/' + event.slug" class="buy-product">
              <div class="product-item">
                <div class="product-body d-flex flex-column justify-content-center mb-4">
                  <h2 class="event-title text-center mb-2">{{ event.name }}</h2>
                  <p class="text-center">{{ event.date }}</p>
                  <div class="product-thumbnail">
                    <figure class="image mt-2 mb-2">
                      <img :src="event.thumbnail" alt="Event Thumbnail" />
                    </figure>
                  </div>
                  <p class="text-center">{{ event.location }}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      events: [],
      company: "",
      sector: "Auswählen",
      first_name: "",
      last_name: "",
      address: "",
      postalcode: "",
      city: "",
      phone: "",
      email: "",
      errors: [],
      launchinformationen: "",
      activeIndex: 0,
      launchItems: [
        { text: "Um was geht es?", content: "" },
        { text: "Interessant, weil…", content: "" },
        { text: "Programm", content: "" },
      ],
      registered: false,
    };
  },
  mounted() {
    document.title = "Ihr Ansprechpartner für Laserscanning - scanner4you";
    this.getEvents();
  },
  methods: {
    async getEvents() {
      try {
        const response = await axios.get('/api/v1/events/');
        this.events = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getLaunchInformationen() {
      await axios
        .get(`/api/v1/content/eventinformation`)
        .then((response) => {
          this.eventinformation = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToRegistration() {
      this.$router.push({ name: 'EventRegistration' });
    },
  },
};
</script>